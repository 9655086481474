import React, { Component, Suspense, lazy, useEffect, useState } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import Spinner from '../app/shared/Spinner';
import Sidebar from './shared/Sidebar';



const Menu = lazy(() => import('./menu/Menu'));
const User = lazy(() => import('./user/User'));
const Media = lazy(() => import('./media/Media'));
const Contrats = lazy(() => import('./webpage/Webpage'));
const Administrateurs = lazy(() => import('./administrateurs/Administrateurs'));
const Parametres = lazy(() => import('./parametres/Parametres'));
const ParametresEntete = lazy(() => import('./parametresEntete/ParametresEntete'));
const ParametresChampsSpecifiques = lazy(() => import('./ParametresChampsSpecifiques/ParametresChampsSpecifiques'));


const Inputextraction = lazy(() => import('./inputextraction/Inputextraction'));
const Outputextraction = lazy(() => import('./outputextraction/Outputrextraction'));

const TableCorrespondance = lazy(() => import('./tablecorrespondance/TableCorrespondance'));
const InfosExport = lazy(() => import('./infosexport/InfosExport'));



const Error404 = lazy(() => import('./error/error404'));

const Site = lazy(() => import('./editeur/Site'));


const Login = lazy(() => import('./user-pages/Login'));






//*ROUTES DE L'APPLICATION
const AppRoutes = (props) => {


  const [isLogined, setIsLogined] = useState(null);
  const [load, setLoad] = useState(false);

  const location = useLocation();

  //*function : pour connaitre le path
  function isPathActive(path) {


    //return <h1>Path is: {location.pathname}</h1>;


    return location.pathname.startsWith(path);
  }

  useEffect(() => {



    //*ON CHECK SI L'UTILISATEUR EST CONNECTÉ OU NON 
    let isLogined = false;
    if (localStorage.getItem("isLogined")) {
      isLogined = true;
    }

    setIsLogined(isLogined);
    setLoad(true);


  }, []);


  if (load) {


    //*SI L'UTILISATEUR N'EST PAS CONNECTÉ
    if (!isLogined) {


      //*ON CHECK LA ROUTE QUI EST DEMANDÉE
      //*SI C'EST UNE SIGNATURE , UN DOCUMENT , CE SONT DES PAGES PUBLICS
      //*SI LA ROUTE COMMENCE PAR "/ADMIN", on demande une connexion 
      return (

        <Suspense fallback={<Spinner />}>
          <Switch>
            <Route path="/" component={Login} />
          </Switch>
        </Suspense>
      )

    } else { //*SI L'UTILISATEUR EST CONNECTE => SI C'EST UN ADMINISTRATEUR






      //*RENDER
      return (


        <Suspense fallback={<Spinner />}>

          <Switch>



            <div className="main-panel" style={{
              marginLeft: 260,
            }}>


              {/**REDIRECTION DE LOGIN VERS LE SITE , la redirection se fais dans le component*/}
              <Route path="/login" component={Login} />


              {/***ROUTES ACESSIBLE A CEUX QUI SONT LOGGES */}

              {/**ON METS LA SIDEBAR UNIQUEMENT POUR LA PARTIE ADMIN*/}
              {isPathActive("/admin") &&
                <Sidebar />
              }

              {/**ON REDIRIGE PAR DEFAUT SUR LE SITE   */}
              <Route exact path="/" >
                <Redirect to="/admin" />
              </Route>
              <Route exact path="/admin" component={Contrats} />
              <Route exact path="/admin/contrats" component={Contrats} />


              <Route exact path="/admin/media" component={Media} />



              <Route exact path="/admin/inputextraction/:id" component={Inputextraction} />
              <Route exact path="/admin/outputextraction/:inputType/:idContrat/:idContratNom" component={Outputextraction} />
              <Route exact path="/admin/outputextraction/:inputType/:idUser/:dateStart/:dateEnd/:idContratNom" component={Outputextraction} />

              <Route exact path="/admin/parametres" component={Parametres} />
              <Route exact path="/admin/entete" component={ParametresEntete} />

              <Route exact path="/admin/champsspecifiques" component={ParametresChampsSpecifiques} />


              {/***ROUTES RESERVES UNIQUEMENT POUR LES ADMINISTRATEURS*/}
              {(localStorage.getItem("statutAdmin") === "true") &&
                <>



                  <Route exact path="/admin/tablecorrespondance/:id" component={TableCorrespondance} />

                  <Route exact path="/admin/infosexport" component={InfosExport} />

                  <Route exact path="/admin/administrateurs" component={Administrateurs} />

                  {/*pour la signature*/}
                  <Route exact path="/admin/site" component={Site} />
                </>
              }

              {/**QUAND RIEN NE MENE NULLE PART , ON REDIRIGE EN 404  
            <Route exact path="/*" component={Error404 } />  
            */}

            </div>
            {/**ON REDIRIGE AU DEPART SUR LE SITE****/}
            <Redirect to="/admin/contrats" />
          </Switch>
        </Suspense>

      );
    }
  } else {
    return (
      <div>

      </div>
    )
  }

}

export default AppRoutes;