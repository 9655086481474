
 //const API_ENDPOINT='http://localhost:8002';
 //const API_ENDPOINT_PYTHON='http://localhost:5002';


 const API_ENDPOINT='https://apiemargement.infa-formation.com';
 const API_ENDPOINT_PYTHON='https://apipythonemargement.infa-formation.com/';



 export {
     API_ENDPOINT,
     API_ENDPOINT_PYTHON
};
